<template>
	<div class="conte" v-html="data.content"></div>
</template>

<script>
import utils from '@/common/utils';

export default {
	data() {
		return {
			data: {}
		};
	},
	mounted() {
		// console.log(this.$route.query.id);
		this.getList(this.$route.query.id);
	},
	methods: {
		getList(id) {
			utils.showSpin();
			this.http.post(`/api/common/news/${id}`, this.param).then(ret => {
				this.data = ret.data;
				utils.closeSpin();
			});
		}
	}
};
</script>

<style scoped>
.conte {
	width: 100%;
	margin: 0 auto;
	text-indent: 2em;
}
</style>
